
import { defineComponent, ref } from 'vue'
import gsap from 'gsap'

export default defineComponent({
  name: 'DropdownContact',
  props: {
    data: Object,
    title: String,
  } as any, // eslint-disable-line
  setup() {
    const isOpen = ref(false)
    const vBar = ref<null | HTMLElement>(null)
    const inner = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const translate = (label: string) => {
      switch (label) {
        case 'name':
          return 'Nom du partenaire'
        case 'unit':
          return 'Unité'
        case 'lastname':
          return 'Nom'
        case 'firstname':
          return 'Prénom'
        case 'address':
          return 'Adresse'
        case 'phone':
          return 'Téléphone'
        case 'email':
          return 'E-mail'
        case 'website':
          return 'Site internet'
        default:
          return 'null'
      }
    }

    const innerAnims = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      enter(el: any, done: any) {
        el.style.height = '0'
        done()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      afterEnter(el: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      leave(el: any, done: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      inner,
      innerAnims,
      isOpen,
      toggle,
      vBar,
      translate,
    }
  },
})
