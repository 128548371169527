
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/projects'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import ArticleHeader from '@/components/article/Header.vue'
import DropdownDetailsList from '@/components/DropdownDetailsList.vue'
import DropdownContact from '@/components/DropdownContact.vue'
import Section from '@/components/inventory/Section.vue'

export default defineComponent({
  name: 'project',
  components: {
    BackBtn,
    ArticleHeader,
    DropdownDetailsList,
    DropdownContact,
    Section,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
