<template>
  <div class="dropdown-details-list">
    <DropdownDetails
      :title="index"
      :value="dropdown"
      v-for="(dropdown, index) in data"
      :key="`dropdown-${index}`"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import DropdownDetails from '@/components/DropdownDetails.vue'

export default defineComponent({
  name: 'DropdownDetailsList',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  components: {
    DropdownDetails,
  },
})
</script>

<style scoped lang="scss"></style>
