<template>
  <div class="dropdown" v-if="data && data.firstname">
    <div class="dropdown__header" @click.prevent="toggle">
      <h4 class="dropdown__header__label">
        {{ title }}
      </h4>
      <div class="dropdown__header__icon"></div>
      <svg
        class="dropdown__header__icon"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path class="dropdown__header__icon__horizontal" d="M0 8h18v2H0z" />
        <path
          class="dropdown__header__icon__vertical"
          d="M8 0h2v18H8z"
          ref="vBar"
        />
      </svg>
    </div>
    <transition
      @enter="innerAnims.enter"
      @afterEnter="innerAnims.afterEnter"
      @leave="innerAnims.leave"
    >
      <div class="dropdown-inner" v-if="isOpen">
        <div class="dropdown__items">
          <div
            class="dropdown__item"
            :key="`item-${label}`"
            v-for="(value, label) in data"
          >
            <div class="dropdown__item__label">{{ translate(label) }}</div>
            <div class="dropdown__item__vale">{{ value }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import gsap from 'gsap'

export default defineComponent({
  name: 'DropdownContact',
  props: {
    data: Object,
    title: String,
  } as any, // eslint-disable-line
  setup() {
    const isOpen = ref(false)
    const vBar = ref<null | HTMLElement>(null)
    const inner = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const translate = (label: string) => {
      switch (label) {
        case 'name':
          return 'Nom du partenaire'
        case 'unit':
          return 'Unité'
        case 'lastname':
          return 'Nom'
        case 'firstname':
          return 'Prénom'
        case 'address':
          return 'Adresse'
        case 'phone':
          return 'Téléphone'
        case 'email':
          return 'E-mail'
        case 'website':
          return 'Site internet'
        default:
          return 'null'
      }
    }

    const innerAnims = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      enter(el: any, done: any) {
        el.style.height = '0'
        done()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      afterEnter(el: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      leave(el: any, done: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      inner,
      innerAnims,
      isOpen,
      toggle,
      vBar,
      translate,
    }
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  & + & {
    margin-top: 0;
    border-top: 1px solid $c-white;
  }
}

.dropdown__header {
  position: relative;
  padding: $spacing $spacing * 3.5 $spacing $spacing;
  background-color: $athens-gray;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.dropdown__header__label {
  color: $c-black;
}

.dropdown__header__icon {
  @include center-y;
  right: 20px;
  width: 16px;
  height: 16px;
  fill: $tangerine;
}

.dropdown-inner {
  overflow: hidden;
  // height: 0;
  background-color: $botticelli;
}

.dropdown__items {
  margin: $spacing * 0.5 $spacing;

  @include mq(xl) {
    margin-bottom: $spacing * 1.4;
  }
}

.dropdown__item {
  display: flex;
  align-items: center;
  padding: $spacing 0;
  font-size: 1.4rem;
}

.dropdown__item + .dropdown__item {
  border-top: 1px solid white;
}

.dropdown__item__label {
  @include fira-bold;

  width: 17rem;
  color: $hippie-blue;
}
</style>
