<template>
  <div class="project" v-if="data">
    <BackBtn class="project__back" />
    <div class="project-inner--large">
      <ArticleHeader pageType="project" :data="data.header" />
      <div class="project__content">
        <div v-if="data.body.objective">
          <h3>Objectifs du partenaire</h3>
          <div
            class="project__content__text"
            v-html="data.body.objective"
          ></div>
        </div>

        <div v-if="data.body.activities">
          <h3>Activités réalisées</h3>
          <div
            class="project__content__text"
            v-html="data.body.activities"
          ></div>
        </div>

        <div v-if="data.body.opinion">
          <h3>Votre avis</h3>

          <h5 class="mt-xs">Qu'avez-vous pensé du projet</h5>

          <div
            class="project__content__text"
            v-html="data.body.opinion.text"
          ></div>

          <h5 class="mt-xs">
            Comment se sont passées les relations avec le partenaire ?
          </h5>
          <div
            class="project__content__text"
            v-html="data.body.opinion.relation"
          ></div>

          <h5 class="mt-xs">Avez-vous des tuyaux ?</h5>
          <div
            class="project__content__text"
            v-html="data.body.opinion.tips"
          ></div>
        </div>

        <div v-if="data.body.details">
          <h3 class="mb-s">Détails pratiques</h3>

          <DropdownDetailsList :data="data.body.details" />
        </div>

        <div v-if="data.body.contact">
          <h3 class="mb-s">Contact</h3>

          <DropdownContact
            title="Partenaire en Belgique"
            :data="data.body.contact.belgian"
          />

          <DropdownContact
            title="Partenaire sur place"
            :data="data.body.contact.onsite"
          />

          <DropdownContact
            title="Chaine Horizon"
            :data="data.body.contact.horizon"
          />
        </div>
      </div>
    </div>
    <div class="project__extended" v-if="data.footer">
      <div class="project-inner--large">
        <div class="project__content">
          <h5 class="mb-xs">Suggestions de projets</h5>

          <div class="project__content__grid">
            <router-link
              v-for="item in data.footer"
              :key="item.url"
              class="m-xs"
              :to="item.url"
            >
              <Section pageType="project" :data="item" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/projects'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import ArticleHeader from '@/components/article/Header.vue'
import DropdownDetailsList from '@/components/DropdownDetailsList.vue'
import DropdownContact from '@/components/DropdownContact.vue'
import Section from '@/components/inventory/Section.vue'

export default defineComponent({
  name: 'project',
  components: {
    BackBtn,
    ArticleHeader,
    DropdownDetailsList,
    DropdownContact,
    Section,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.project {
  @include details-intro;

  position: relative;
  background-color: $c-white;

  ::v-deep {
    .dropdown {
      margin-bottom: 0;
    }

    .section {
      .icon-arrow {
        top: auto;
        bottom: 2rem;
        transform: translateY(0);
      }

      &:hover {
        .icon-arrow {
          transform: translateX(3px);
        }
      }
    }
  }
}

.project__extended {
  background-color: $athens-gray;
}

[class*='project-inner--'],
.project-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  h3 + h5 {
    margin-top: 0;
  }

  @include mq(s) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='project__content--'],
.project__content {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}

.project__content h4 {
  margin-top: $spacing * 1.5;
  margin-bottom: $spacing / 4;

  &:first-child {
    margin-top: 0;
  }
}

.project__content > div {
  margin: 0 0 $spacing * 1.5;
}

.project__content ::v-deep(img) {
  margin-bottom: $spacing / 2;
}

.project__content ::v-deep(ul) {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing * 1.5;
}
.project__content ::v-deep(li) {
  display: flex;

  & + li {
    margin-top: $spacing / 2;
  }

  &::before {
    content: '\2022';
    color: $regal-blue;
    display: inline-block;
    width: 6px;
    margin-right: $spacing / 2;
  }
}

.project__content__text {
  ::v-deep(p) {
    margin-bottom: $spacing / 2;
  }
}

.project__content__grid {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(1, 1fr);

  > * {
    margin: 0;
  }

  @include mq(s) {
    grid-template-columns: repeat(2, 1fr);
  }

  ::v-deep(.section) {
    margin-bottom: 0;
  }
}
</style>
