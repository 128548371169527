
import { defineComponent } from 'vue'

import DropdownDetails from '@/components/DropdownDetails.vue'

export default defineComponent({
  name: 'DropdownDetailsList',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  components: {
    DropdownDetails,
  },
})
